/* eslint-disable react/forbid-prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';
import { ViewWrapper, ViewPaper, Table } from '@mwp/frontend-components';
import UserAddDialog from 'components/user/UserAddDialog';

const RolesList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  li {
    margin: 0 ${({ theme }) => theme.spacing()}px 0 0;
    padding: 0;
  }
`;

const UsersView = ({
  className,
  tableData,
  tableTitle,
  onRowClick,
  rowKey,
  displayAddDialog,
  onAddUser,
}) => {
  const classes = ['UsersView'];
  if (className) classes.push(className);

  const mapTableData = () => tableData
    .map((user) => ({
      Email: user.email,
      Roles: <RolesList>{user.roles.map((role) => <li><Chip size="small" label={role} /></li>)}</RolesList>,
    }));

  return (
    <ViewWrapper className={classes.join(' ')}>
      <ViewPaper>
        <Table
          tableData={mapTableData()}
          tableTitle={tableTitle}
          onRowClick={(row) => onRowClick(row.Email)}
          rowKey={rowKey}
          theme="light"
        />
      </ViewPaper>
      <UserAddDialog
        open={displayAddDialog}
        onDismiss={() => onAddUser(null)}
        onConfirm={onAddUser}
      />
    </ViewWrapper>

  );
};

UsersView.displayName = 'UsersView';

UsersView.propTypes = {
  className: PropTypes.string,
  tableData: PropTypes.arrayOf(PropTypes.any).isRequired,
  tableTitle: PropTypes.string,
  onRowClick: PropTypes.func,
  rowKey: PropTypes.string,
  displayAddDialog: PropTypes.bool,
  onAddUser: PropTypes.func,
};

UsersView.defaultProps = {
  className: null,
  tableTitle: null,
  onRowClick: () => {},
  displayAddDialog: false,
  onAddUser: () => {},
  rowKey: null,
};

export default UsersView;
