/* eslint-disable react/jsx-no-bind */

import React from 'react';
import PropTypes from 'prop-types';
import SessionWidget from 'components/wmc/SessionsWidget';

const SessionWidgetContainer = ({
  className,
  sendSessionMessage,
  fetchSessions,
  terminateSession,
}) => {
  const [sessions, setSessions] = React.useState([]);

  function handleFetchRequest() {
    fetchSessions().then((res) => {
      setSessions(
        res.map((r) => {
          // this is to avoid loosing the existing selection (if any)
          const oldSessionObj = sessions.find(
            (s) => s.metadata.id === r.metadata.id,
          );
          return oldSessionObj || r;
        }),
      );
    });
  }

  const classes = ['SessionWidgetContainer'];
  if (className) classes.push(className);

  function toggleSessionSelection(session, state) {
    const selectedSession = sessions.find(
      (s) => s.metadata.id === session.metadata.id,
    );

    selectedSession.metadata.messagingState = null;
    selectedSession.metadata.selected = state !== undefined
      ? state : !selectedSession.metadata.selected;
    setSessions([...sessions]);
  }

  function setSessionMessagingState(session, state) {
    const selectedSession = sessions.find(
      (s) => s.metadata.id === session.metadata.id,
    );

    selectedSession.metadata.messagingState = state;
    setSessions([...sessions]);
  }

  function handleRemoveRecipients(recipient) {
    toggleSessionSelection(recipient);
  }

  function handleSelectAll(state) {
    setSessions(
      sessions.map((s) => ({
        ...s,
        metadata: {
          ...s.metadata,
          messagingState: null,
          selected: state,
        },
      })),
    );
  }

  function handleTerminateSession() {
    const sessionsToTerminate = sessions.filter((s) => s.metadata.selected);
    Promise.all(
      sessionsToTerminate.map((s) => terminateSession(s.metadata.id)),
    ).finally(handleFetchRequest);
  }

  function onSendMessage(msg) {
    const recipients = sessions.filter((s) => s.metadata.selected);

    recipients.forEach((r) => setSessionMessagingState(r, 'sending'));

    Promise.all(
      recipients.map((r) => sendSessionMessage(r.metadata.id, msg)
        .then((result) => {
          if (result.ok) {
            setSessionMessagingState(r, 'sent');

            // auto-hiding
            setTimeout(() => {
              toggleSessionSelection(r, false);
            }, 2000);
          } else {
            setSessionMessagingState(r, 'error');
          }
        })
        .catch(() => {
          setSessionMessagingState(r, 'error');
        })),
    ).finally();
  }

  React.useEffect(() => {
    handleFetchRequest();
  }, []);

  return (
    <SessionWidget
      sessions={sessions}
      onSessionSelected={toggleSessionSelection}
      selectAll={handleSelectAll}
      recipients={sessions.filter((s) => s.metadata.selected)}
      onRemoveRecipient={handleRemoveRecipients}
      onSendMessage={onSendMessage}
      onRefreshRequested={handleFetchRequest}
      onTerminateSession={handleTerminateSession}
      contentHeight="40vh"
    />
  );
};

SessionWidgetContainer.displayName = 'SessionWidgetContainer';

SessionWidgetContainer.propTypes = {
  className: PropTypes.string,
  sendSessionMessage: PropTypes.func.isRequired,
  fetchSessions: PropTypes.func.isRequired,
  terminateSession: PropTypes.func.isRequired,
};

SessionWidgetContainer.defaultProps = {
  className: null,
};

export default SessionWidgetContainer;
