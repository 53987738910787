/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLayout } from 'store/actions';
import { Header } from '@mwp/frontend-components';
import ToolbarContainer from 'containers/ToolbarContainer';
import SessionWidgetContainer from 'containers/widgets/SessionWidgetContainer';
import MapWidgetContainer from 'containers/widgets/MapWidgetContainer';
import LoginsWidgetContainer from 'containers/widgets/LoginsWidgetContainer';
import { sendSessionMessage, terminateSession } from 'gateways';
import { fetchSessions } from 'gateways/WorkerManagerGateway';
import MessagesWidgetContainer from 'containers/widgets/MessagesWidgetContainer';
import EventsWidgetContainer from 'containers/widgets/EventsWidgetContainer';
import moment from 'moment';
import HomeView from '../views/HomeView';
import NavigationMenuContainer from './NavigationMenuContainer';
import { usePlugins } from '../context/PluginContext';

const HomeContainer = () => {
  const dispatch = useDispatch();
  const userCredentials = useSelector((state) => state.userCredentials);
  const { plugins } = usePlugins();

  function handleFetchSessions() {
    return fetchSessions(userCredentials).then((res) => res.map((s) => ({
      Name: s.endUser.name,
      Time: moment(s.startTime).format('YYYY/MM/DD HH:mm:ss'),
      'Last active': moment(s.lastUpdated).format('YYYY/MM/DD HH:mm:ss'),
      metadata: {
        trackableId: s.endUser.trackableId,
        id: s.sessionId,
      },
    })));
  }

  function handleSendSessionMessage(sessionId, msg) {
    return sendSessionMessage(userCredentials, sessionId, msg);
  }

  useEffect(() => {
    dispatch(
      setLayout({
        header: <Header toolbar={<ToolbarContainer />} />,
        aside: <NavigationMenuContainer />,
      }),
    );
  }, [dispatch]);

  function handleTerminateSession(sessionId) {
    return terminateSession(userCredentials, sessionId);
  }

  const renderPluginWidgets = () => plugins
    .flatMap((plugin) => plugin.widgets.map((widget, i) => ([plugin, widget, i])))
    .map(([plugin, Widget, i]) => <Widget key={plugin.id + i} />);

  return (
    <HomeView>
      <SessionWidgetContainer
        sendSessionMessage={handleSendSessionMessage}
        fetchSessions={handleFetchSessions}
        terminateSession={handleTerminateSession}
      />
      <LoginsWidgetContainer />
      <MapWidgetContainer />
      <EventsWidgetContainer />
      <MessagesWidgetContainer />
      {renderPluginWidgets()}
    </HomeView>
  );
};

export default HomeContainer;

HomeContainer.displayName = 'HomeContainer';
