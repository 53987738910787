import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUsers } from 'store/actions';

export default () => {
  const dispatch = useDispatch();
  const [dataFetched, setDataFetched] = useState(false);
  const users = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(fetchUsers()).then(() => {
      setDataFetched(true);
    });
  }, [dispatch]);

  return dataFetched ? users : null;
};
