import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchWorkers } from 'store/actions';

export default () => {
  const dispatch = useDispatch();
  const [dataFetched, setDataFetched] = useState(false);
  const workers = useSelector((state) => state.workers);

  useEffect(() => {
    dispatch(fetchWorkers()).then(() => {
      setDataFetched(true);
    });
  }, []);

  return dataFetched ? workers : null;
};
