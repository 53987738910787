/* eslint-disable react/jsx-no-bind */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Table, WidgetView, ConfirmationDialog, Carousel,
} from '@mwp/frontend-components';
import {
  Box, IconButton, Button, Typography, Chip,
} from '@material-ui/core';
import MessageIcon from '@material-ui/icons/MessageOutlined';
import BackIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';
import CloudIcon from '@material-ui/icons/CloudOffOutlined';
import RefreshIcon from '@material-ui/icons/RefreshOutlined';
import CancelIcon from '@material-ui/icons/DeleteForeverOutlined';
import SendMessageForm from './SendMessageForm';

const Wrapper = styled(WidgetView)`
  .tableView {
    height: ${({ contentHeight }) => contentHeight};
    overflow: auto;
  }

  .content {
    position: relative;
    height: ${({ contentHeight }) => contentHeight};
    overflow: auto;
  }
`;

const ButtonsBar = styled.div`
  padding: 0 ${({ theme }) => theme.spacing(1)}px;
  display: flex;

  .selectAllBtn {
    margin-right: auto;
  }
`;

const WidgetTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(0, 1, 0, 2)};
`;

const ConfirmationBody = styled.div`
  .sessions{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    >* {
      margin: ${({ theme }) => theme.spacing(0.5)}px;
    }
  }
`;
const TablePlaceholder = styled.p`
  margin: 0;
  display: contents;
  svg {
    margin-left: 0.5em;
    font-size: 2rem;
  }
`;

const SessionWidget = ({
  className,
  sessions,
  onSessionSelected,
  selectAll,
  recipients,
  onRemoveRecipient,
  onSendMessage,
  onRefreshRequested,
  onTerminateSession,
  contentHeight,
}) => {
  const SESSION_TABLE = 0;
  const MESSAGING = 1;

  const [tileIdx, setTileIdx] = React.useState(0);

  const classes = ['SessionWidget'];
  if (className) classes.push(className);

  const someSessionsSelected = !!sessions.find((s) => s.metadata.selected);
  const [showTerminateConfirm, setShowTerminateConfirm] = React.useState(false);

  function toggleTerminateConfirm(state) {
    setShowTerminateConfirm(
      state === undefined ? !showTerminateConfirm : state,
    );
  }

  function handleSelectAll() {
    if (selectAll) selectAll(!someSessionsSelected);
  }

  function showMessaging() {
    setTileIdx(MESSAGING);
  }

  function showSessionsTable() {
    setTileIdx(SESSION_TABLE);
  }

  React.useEffect(() => {
    if (recipients.length === 0) {
      setTileIdx(SESSION_TABLE);
    }
  }, [recipients.length]);

  const Actions = () => (
    <ButtonsBar>
      {tileIdx === SESSION_TABLE && (
        <>
          <Button onClick={handleSelectAll} className="selectAllBtn">
            {`${someSessionsSelected ? 'Des' : 'S'}elect all`}
          </Button>
          <IconButton
            onClick={() => { toggleTerminateConfirm(); }}
            title="Terminate the selected session/s"
            disabled={!someSessionsSelected}
          >
            <CancelIcon />
          </IconButton>
          <IconButton
            onClick={showMessaging}
            title="Send a message to the selected session/s"
            disabled={!someSessionsSelected}
          >
            <MessageIcon />
          </IconButton>
        </>
      )}

      {tileIdx === MESSAGING && (
        <Button onClick={showSessionsTable} className="backBtn">
          <BackIcon />
          Back
        </Button>
      )}
    </ButtonsBar>
  );

  const WidgetTitleComponent = () => (
    <WidgetTitle>
      <Typography variant="h6">Sessions</Typography>
      <IconButton onClick={onRefreshRequested}>
        <RefreshIcon />
      </IconButton>
    </WidgetTitle>
  );

  return (
    <Wrapper
      className={classes.join(' ')}
      title={<WidgetTitleComponent />}
      footer={<Actions />}
      contentHeight={contentHeight}
      onRefreshRequested={onRefreshRequested}
    >
      <Box className="content" p={1}>
        <Carousel selectedTileIdx={tileIdx}>
          <Table
            id="table"
            theme="light"
            tableData={sessions}
            rowKey="Name"
            onRowClick={onSessionSelected}
            noPagination
            placeholder={(
              <TablePlaceholder>
                <span>Sorry, there are no active sessions right now!</span>
                <CloudIcon />
              </TablePlaceholder>
            )}
          />
          <SendMessageForm
            id="msgForm"
            recipients={recipients}
            onRemoveRecipient={onRemoveRecipient}
            onSubmit={onSendMessage}
          />
        </Carousel>
      </Box>

      <ConfirmationDialog
        title="Terminate session/s"
        content={(
          <ConfirmationBody>
            <p>Do you want to terminate the selected session/s?</p>
            <div className="sessions">
              {sessions
                .filter((s) => s.metadata.selected)
                .map((s) => (
                  <Chip key={s.metadata.id} label={s.Name} />
                ))}
            </div>
          </ConfirmationBody>
        )}
        dismissButtonLabel="Cancel"
        open={showTerminateConfirm}
        confirmButtonLabel="Terminate"
        handleConfirm={() => {
          toggleTerminateConfirm(false);
          onTerminateSession();
        }}
        handleDismiss={() => {
          toggleTerminateConfirm(false);
        }}
      />

    </Wrapper>
  );
};

SessionWidget.displayName = 'SessionWidget';

SessionWidget.propTypes = {
  className: PropTypes.string,
  sessions: PropTypes.arrayOf(PropTypes.shape({})),
  onSessionSelected: PropTypes.func,
  selectAll: PropTypes.func,
  recipients: PropTypes.arrayOf(PropTypes.shape({})),
  onRemoveRecipient: PropTypes.func,
  onSendMessage: PropTypes.func.isRequired,
  onTerminateSession: PropTypes.func.isRequired,
  contentHeight: PropTypes.string,
  onRefreshRequested: PropTypes.func,
};

SessionWidget.defaultProps = {
  className: null,
  sessions: [],
  onSessionSelected: null,
  selectAll: null,
  recipients: [],
  onRemoveRecipient: PropTypes.func,
  contentHeight: '400px',
  onRefreshRequested: null,
};

export default SessionWidget;
