/* eslint-disable curly */
/* eslint-disable default-case */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/forbid-prop-types */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styled from 'styled-components';
import { SplitView } from '@mwp/frontend-components';
import Paper from '@material-ui/core/Paper';
import CalendarEditView from 'views/CalendarEditView';

const localizer = momentLocalizer(moment);

const StyledCalendarView = styled.div`
  height: 100%;
  width: 100%;
`;

const CalendarView = ({
  events,
  onEventSave,
  onEventDelete,
}) => {
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [calendarEvents, setCalendarEvents] = useState([]);

  useEffect(() => {
    const rangeStart = moment(calendarDate).startOf('month').subtract(6, 'days');
    const rangeEnd = moment(calendarDate).endOf('month').add(6, 'days');

    const processedEvents = [];
    events.forEach((event) => {
      const startTime = moment.unix(event.startTime);
      if (event.repeat === 'never') {
        if (!startTime.isBefore(rangeStart)) processedEvents.push({
          ...event,
          calendarStartTime: event.startTime,
          calendarEndTime: event.endTime,
        });
      } else {
        let nextDate;
        if (!startTime.isBefore(rangeStart)) {
          nextDate = startTime.clone();
        } else {
          nextDate = rangeStart.clone()
            .set({
              hour: startTime.hour(),
              minute: startTime.minute(),
              second: startTime.second(),
              millisecond: 0,
            });
        }

        switch (event.repeat) {
          case 'weekly':
            nextDate.add(startTime.day() - nextDate.day(), 'days');
            break;
          case 'monthly':
            nextDate.date(Math.min(startTime.date(), nextDate.daysInMonth()));
            break;
          case 'yearly':
            nextDate.month(startTime.month());
            nextDate.date(Math.min(startTime.date(), nextDate.daysInMonth()));
            break;
        }

        const eventDuration = (event.endTime) ? event.endTime - event.startTime : 0;
        while (nextDate.isBefore(rangeEnd)) {
          processedEvents.push({
            ...event,
            calendarStartTime: nextDate.unix(),
            calendarEndTime: nextDate.unix() + eventDuration,
          });
          switch (event.repeat) {
            case 'daily':
              nextDate.add(1, 'day');
              break;
            case 'weekly':
              nextDate.add(1, 'week');
              break;
            case 'monthly':
              nextDate.add(1, 'month');
              nextDate.date(Math.min(startTime.date(), nextDate.daysInMonth()));
              break;
            case 'yearly':
              nextDate.add(1, 'year');
              nextDate.date(Math.min(startTime.date(), nextDate.daysInMonth()));
              break;
          }
        }
      }
    });
    setCalendarEvents(processedEvents);
  }, [events, calendarDate]);

  const [editEvent, setEditEvent] = useState(null);

  const handleEventSelection = (event) => {
    if (!editEvent) setEditEvent(event);
  };

  const handleSlotSelection = (slot) => {
    if (!editEvent) setEditEvent({
      title: '',
      description: '',
      startTime: slot.start.getTime() / 1000,
      endTime: slot.end.getTime() / 1000,
      repeat: 'never',
      notifyTimeBefore: 'none',
      public: false,
    });
  };

  const handleNavigate = (date) => {
    // update calendar date only if the month has changed
    if (calendarDate.getMonth() !== date.getMonth()
      || calendarDate.getFullYear() !== date.getFullYear()) {
      setCalendarDate(date);
    }
  };

  const handleSave = (event) => {
    setEditEvent(null);
    onEventSave(event);
  };

  const handleDelete = (eventId) => {
    setEditEvent(null);
    onEventDelete(eventId);
  };

  return (
    <StyledCalendarView className="CalendarView">
      <SplitView ratios="65%,35%" p={1}>
        <Paper>
          <Calendar
            popup
            selectable
            localizer={localizer}
            events={calendarEvents}
            startAccessor={(event) => new Date(event.calendarStartTime * 1000)}
            endAccessor={(event) => new Date(event.calendarEndTime * 1000)}
            defaultView="month"
            style={{ height: 560 }}
            onSelectEvent={handleEventSelection}
            onSelectSlot={handleSlotSelection}
            onNavigate={handleNavigate}
          />
        </Paper>
        {editEvent && (
          <CalendarEditView
            event={editEvent}
            onCancel={() => setEditEvent(null)}
            onDelete={handleDelete}
            onSave={handleSave} />
        )}
      </SplitView>
    </StyledCalendarView>
  );
};

CalendarView.displayName = 'CalendarView';

CalendarView.propTypes = {
  events: PropTypes.arrayOf(PropTypes.any).isRequired,
  onEventSave: PropTypes.func.isRequired,
  onEventDelete: PropTypes.func.isRequired,
};

export default CalendarView;
