/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { setLayout } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import queryString from 'query-string';
import styled from 'styled-components';
import { Header, ViewWrapper, ViewPaper, Table } from '@mwp/frontend-components';
import ToolbarContainer from './ToolbarContainer';
import NavigationMenuContainer from './NavigationMenuContainer';
import FilterList from '@material-ui/icons/FilterList';
import { useEvents, useWorkers, useApps } from 'hooks';
import moment from 'moment';
import { Chip, IconButton, Paper } from '@material-ui/core';
import EventFilterView from 'views/EventFilterView';

const hoursTimeSpan = 24
const timeSpanInMs =  hoursTimeSpan * 60 * 60 * 1000;

function buildFilter(now, location) {
  const {
    from = now - timeSpanInMs,
    to = now,
    appIds,
    originatorIds,
  } = queryString.parse(location.search);

  return {
    from: from ?  new Date(parseInt(from, 10)): undefined,
    to: to ? new Date(parseInt(to, 10)) : undefined,
    workflowAppId: (appIds && appIds.split(',')) || undefined,
    originator: (originatorIds && originatorIds.split(',')) || undefined,
  };
}

const headersCSV = [
  { label: 'ID', key: 'id' },
  { label: 'Workflow App ID', key: 'Id' },
  { label: 'Timestamp', key: 'Timestamp' },
  { label: 'Originator', key: 'Originator' },
  { label: 'Detail', key: 'Detail' },
];

const DownloadCsvArea = styled.div`
  background: ${({ theme }) => theme.palette.background.paper};
  text-align: end;
  flex-shrink: 0;
`;

const filterLabelsMap = {
  from: 'From',
  to: 'To',
  workflowAppId: 'Id',
  originator: 'Originator',
};

function formatFilterValue (filterName, value) {
  if (filterName === 'from' || filterName === 'to') {
    return moment(value).format('DD/MM/YYYY HH:mm');
  }

  return value;
}

function canDelete(filterName){
  if (filterName === 'from' || filterName === 'to') {
    return false;
  }
  return true;
}

const EventsContainer = ({ location, history }) => {
  const dispatch = useDispatch();
  const [displayFilter, setDisplayFilter] = useState(false);
  const [now]=useState(Date.now())

  const filters = React.useMemo(() => buildFilter(now, location), [location.search]);

  const workflowAppIdMap = useApps().map(o=>o.id);
  const workers = useWorkers() || [];
  const originatorsArr = workers.map(o => o.username);
  const fetchedData = useEvents(filters);

  const events = fetchedData.map((e) => ({
    Timestamp: moment(e.timestamp * 1000).format('YYYY/MM/DD HH:mm:ss'),
    Originator: e.originator,
    'Id': e.workflowAppId,
    Detail: e.detail,
    id: e.id,
  })).reverse();

  const displayFilterButton = (
    <IconButton
      color="inherit"
      key="setFiltersButton"
      onClick={() => setDisplayFilter(true)}
    >
      <FilterList />
    </IconButton>
  );

  const handleFilterChange = (newFilter) => {
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(
        {
          ...newFilter,
          to: newFilter.to && newFilter.to.getTime(),
          from: newFilter.from && newFilter.from.getTime(),
        },
        { arrayFormat: 'comma' },
      ),
    });
    setDisplayFilter(false);
  };

  const onClearFilter = (filterName) => {
    const newFilters = {...filters};
    delete newFilters[filterName];
    handleFilterChange(newFilters);
  }

  useEffect(() => {
    dispatch(
      setLayout({
        header: (
          <Header
            toolbar={<ToolbarContainer buttons={[displayFilterButton]} />}
          />
        ),
        aside: <NavigationMenuContainer />,
      }),
    );
  }, [dispatch]);

  return (
    <ViewWrapper>
      <ViewPaper>
        <DownloadCsvArea>
          <CSVLink
            data={events}
            headers={headersCSV}
            filename="events_export.csv"
          >
            Export events (CSV)
          </CSVLink>
        </DownloadCsvArea>
        <Paper style={{ background: 'transparent', boxShadow: 'none' }}>
          <div style={{ margin: 'auto 20px auto auto' }}>
            {
                    filters && Object.keys(filters).map((filterName) => (
                      filters[filterName]
                        && (
                        <Chip
                          key={filterName}
                          onDelete={canDelete(filterName) ? () => { onClearFilter(filterName) } : null}                   
                          label={`${filterLabelsMap[filterName]}: ${formatFilterValue(filterName, filters[filterName])}`}
                          style={{ margin: '3px' }}
                        />
                        )
                    ))
                  }
          </div>
        </Paper>
        <Table
          tableTitle={`Events:`}
          tableData={events}
          theme="light"
          rowKey="id"
          hideKey
        />
      </ViewPaper>
      <EventFilterView
        onDismiss={() => setDisplayFilter(false)}
        onSubmit={handleFilterChange}
        filters={filters}
        open={displayFilter}
        appIds={Object.values(workflowAppIdMap)}
        originatorIds={originatorsArr}
      />
    </ViewWrapper>
  );
};

EventsContainer.displayName = 'EventsContainer';

EventsContainer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default EventsContainer;
