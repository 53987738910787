/* eslint-disable react/jsx-no-bind */

import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { ConfirmationDialog } from '@mwp/frontend-components';

const addressTypeFieldMap = {
  'com.synergizeit.mobiletracker.notification.subscription.PhoneNumber': {
    field: 'digits',
    label: 'Phone number',
  },
  'com.synergizeit.mobiletracker.notification.subscription.HttpAddress': {
    field: 'url',
    label: 'URL',
  },
  'com.synergizeit.mobiletracker.notification.subscription.EmailAddress': {
    field: 'addressString',
    label: 'Email',
  },
};

const eventTypeLabelMap = {
  GEOFENCE_EVENT: 'Geofence',
  LOCATION_EVENT: 'Location',
  TRACKABLE_EVENT: 'Trackable',
  USER_EVENT: 'User',
};

const StyledNotificationEditView = styled(Container)`
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

const ButtonsBar = styled(Box)`
  padding: ${({ theme }) => theme.spacing(2, 1)};
  display: flex;
  justify-content: flex-end;
  button {
    margin: 0 ${({ theme }) => theme.spacing(1)}px;
  }

  .deleteBtn {
    order: -1;
    margin-right: auto;
  }

  .saveBtn {
    order: 1;
  }
`;

const NotificationEditView = ({
  subscription,
  onSubmit,
  onCancel,
  onDelete,
  onChange,
}) => {
  const [eventType, setEventType] = useState(subscription && subscription.eventType);
  const [addressType, setAddressType] = useState((subscription && subscription.address['@class']) || '');
  const [addressString, setAddressString] = useState(subscription && subscription.address[addressTypeFieldMap[subscription.address['@class']].field]);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();

    const address = {
      addressId: (subscription && subscription.address.addressId) || 0,
      '@class': addressType,
      [addressTypeFieldMap[addressType].field]: addressString,
    };

    onSubmit({
      ...subscription,
      id: (subscription && subscription.subscriptionId) || 0,
      eventType,
      address,
    });
  }

  function handleEventTypeChange(e) {
    setEventType(e.target.value);
  }

  function handleAddressTypeChange(e) {
    setAddressType(e.target.value);
  }

  function handleAddressChange(e) {
    setAddressString(e.target.value);
  }

  return (
    <StyledNotificationEditView>
      <Paper>
        <form
          onChange={onChange}
          onSubmit={handleSubmit}
        >
          <Box p={2}>
            <FormControl
              fullWidth
            >
              <InputLabel htmlFor="eventType">Event type</InputLabel>
              <Select
                name="eventType"
                value={eventType}
                onChange={handleEventTypeChange}
              >
                {Object.entries(eventTypeLabelMap).map(([typeName, label]) => (
                  <MenuItem key={typeName} value={typeName}>{label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box p={2}>
            <FormControl
              fullWidth
            >
              <InputLabel htmlFor="addressType">Address type</InputLabel>
              <Select
                name="addressType"
                value={addressType}
                onChange={handleAddressTypeChange}
              >
                {Object.entries(addressTypeFieldMap).map(([typeName, { label }]) => (
                  <MenuItem key={typeName} value={typeName}>{label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box p={2}>
            <TextField
              name="address"
              label="Address"
              placeholder="Address"
              fullWidth
              margin="normal"
              onChange={handleAddressChange}
              value={addressString}
            />
            <ButtonsBar>
              <Button
                className="saveBtn"
                type="submit"
                color="primary"
                variant="outlined"
              >
                Save
              </Button>
              <Button
                className="cancelBtn"
                type="button"
                color="primary"
                onClick={onCancel}
              >
                Cancel
              </Button>
              {subscription && (
                <Button
                  className="deleteBtn"
                  type="button"
                  color="secondary"
                  onClick={() => setOpenDeleteConfirm(true)}
                >
                  Delete
                </Button>
              )}
            </ButtonsBar>
          </Box>
        </form>
      </Paper>
      <ConfirmationDialog
        title="Delete notification"
        content="Do you want to remove this notification?"
        dismissButtonLabel="Close"
        open={openDeleteConfirm}
        confirmButtonLabel="Delete"
        handleConfirm={() => onDelete(subscription)}
        handleDismiss={() => setOpenDeleteConfirm(false)}
      />
    </StyledNotificationEditView>
  );
};

NotificationEditView.displayName = 'NotificationEditView';

NotificationEditView.propTypes = {
  subscription: PropTypes.shape({
    subscriptionId: PropTypes.number.isRequired,
    eventType: PropTypes.string.isRequired,
    address: PropTypes.shape({
      addressId: PropTypes.number.isRequired,
      '@class': PropTypes.string.isRequired,
      digits: PropTypes.string,
      url: PropTypes.string,
      phoneNumber: PropTypes.string,
    }).isRequired,
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

NotificationEditView.defaultProps = {
  subscription: null,
};

export default NotificationEditView;
