/* eslint-disable react/jsx-no-bind */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { addMessage, setLayout } from 'store/actions';
import { Header, Spinner, ConfirmationDialog } from '@mwp/frontend-components';
import ToolbarContainer from 'containers/ToolbarContainer';
import NavigationMenuContainer from 'containers/NavigationMenuContainer';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import UserEditView from 'views/UserEditView';

import useUserServiceGateway from 'hooks/useUserServiceGateways';

const UserEditContainer = ({ match, history }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState();
  const loggedInUser = useSelector((state) => state.user);
  const [error, setError] = useState();
  const { setRoles, getUser, deleteUser } = useUserServiceGateway();

  const email = match.params && match.params.email;

  const [confirmationOptions, setConfirmationOptions] = React.useState({
    open: false,
    title: 'Remove user:',
    confirmButtonLabel: 'Remove',
    dismissButtonLabel: 'Cancel',
    handleDismiss: () => {
      setConfirmationOptions({
        ...confirmationOptions,
        open: false,
      });
    },
    handleConfirm: () => {
      setConfirmationOptions({
        ...confirmationOptions,
        open: false,
      });
      deleteUser(email).then((res) => {
        if (res.error) {
          setError(res.error);
        } else {
          history.goBack();
        }
      });
    },
  });

  useEffect(() => {
    dispatch(setLayout({
      header: <Header toolbar={<ToolbarContainer />} />,
      aside: <NavigationMenuContainer />,
    }));
  }, []);

  useEffect(() => {
    getUser(email).then((u) => setUser(u));
  }, [email]);

  function handleSubmit(userEmail, roles) {
    setRoles(userEmail, roles)
      .then(history.goBack())
      .catch(() => {
        dispatch(addMessage({ severity: 'error', text: 'Something went wrong.' }));
      });
  }

  function handleCancel() {
    history.goBack();
  }

  function handleChange() {
    // we could use this to show a warning to notify the user of unsaved changes
  }

  function handleDelete() {
    setConfirmationOptions({
      ...confirmationOptions,
      content: `Do you want to remove ${user.email} from this group?`,
      open: true,
    });
  }

  function handleCloseSnackbar() {
    setError(null);
  }

  return (
    <>
      <ConfirmationDialog {...confirmationOptions} />
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="error">
          {error}
        </MuiAlert>
      </Snackbar>

      { (!user) ? <Spinner relativeToParent /> : (

        <UserEditView
          username={user && user.email}
          roles={user && user.roles}
          readOnly={loggedInUser && loggedInUser.roles && !loggedInUser.roles.includes('admin')}
          onChange={handleChange}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          onDelete={handleDelete}
        />

      ) }
    </>
  );
};

UserEditContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      email: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape(
    { goBack: PropTypes.func.isRequired },
  ).isRequired,

};

export default UserEditContainer;
