/* eslint-disable react/jsx-no-bind */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { Header, Spinner } from '@mwp/frontend-components';
import useWorkers from 'hooks/useWorkers';
import { setAppBusy, setLayout } from '../store/actions';
import ToolbarContainer from './ToolbarContainer';
import NavigationMenuContainer from './NavigationMenuContainer';
import { fetchLastLocations, sendSms } from '../gateways';
import WorkersView from '../views/WorkersView';

const WorkersContainer = ({ history }) => {
  const userCredentials = useSelector((state) => state.userCredentials);
  const user = useSelector((state) => state.user);
  const isAdmin = user.roles && user.roles.includes('admin');
  const workers = useWorkers();
  const [locations, setLocations] = useState({});

  const onWorkerSelected = (worker) => {
    history.push(`/workers/${worker.trackableId}/edit`);
  };
  function handleAdd() {
    history.push('/workers/add');
  }

  const addButton = <IconButton color="inherit" key="addButton" onClick={handleAdd}><AddIcon /></IconButton>;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setLayout({
        header: <Header toolbar={<ToolbarContainer buttons={isAdmin && [addButton]} />} />,
        aside: <NavigationMenuContainer />,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (workers && workers.length) {
      fetchLastLocations(userCredentials, workers.map((w) => w.trackableId))
        .then((lastLocations) => lastLocations.reduce((locationsObject, loc) => {
          // eslint-disable-next-line no-param-reassign
          locationsObject[loc.trackableId] = loc;
          return locationsObject;
        }, {}))
        .then(setLocations);
    }
  }, [dispatch, workers]);

  const mapLocation = (location) => location && {
    lng: location.qualifiedCoordinates.longitude,
    lat: location.qualifiedCoordinates.latitude,
  };

  const mapWorkers = () => workers.map((worker) => {
    const w = worker;
    w.location = mapLocation(locations[w.trackableId]);
    return w;
  });

  const handleSendSms = (trackableId, text) => (
    sendSms(userCredentials, [trackableId], text)
      .then(() => dispatch(setAppBusy(true)))
      .finally(() => dispatch(setAppBusy(false)))
  );

  return (
    <>
      {!workers && <Spinner relativeToParent />}
      {!!workers && (
        <WorkersView
          workers={mapWorkers() || []}
          onSelectWorker={onWorkerSelected}
          canEdit={isAdmin}
          mapProps={{
            center: {
              lat: 51.429573,
              lng: -0.34625,
            },
            zoom: 8,
          }}
          onSendSms={handleSendSms}
        />
      )}
    </>
  );
};

export default WorkersContainer;

WorkersContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
