/* eslint-disable react/jsx-no-bind */

import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import MapWidget from 'components/wmc/MapWidget';
import { fetchLastLocations, listWorkersAsMap } from 'gateways';
import moment from 'moment';

const MapWidgetContainer = ({ className }) => {
  const classes = ['MapWidgetContainer'];
  const history = useHistory();
  const userCredentials = useSelector((state) => state.userCredentials);
  const [workers, setWorkers] = React.useState({});
  const [locations, setLocations] = React.useState([]);

  function handleLocationSelection(data) {
    history.push(`/workers/locations?ids=${data.trackableId}`);
  }

  React.useEffect(() => {
    listWorkersAsMap(userCredentials)
      .then(setWorkers);
  }, [setWorkers, userCredentials]);

  React.useEffect(() => {
    const workersList = Object.keys(workers);
    if (!workersList.length) {
      return;
    }
    fetchLastLocations(userCredentials, workersList).then(setLocations);
  }, [setLocations, workers, userCredentials]);

  if (className) classes.push(className);

  const yesterday = moment().subtract(1, 'days');
  const mapLocations = () => locations
    .map((location) => {
      const t = moment(location.timestamp);
      return {
        lng: location.qualifiedCoordinates.longitude,
        lat: location.qualifiedCoordinates.latitude,
        label: workers[location.trackableId].name,
        sublabel: (t.isBefore(yesterday)) ? t.format('DD/MM/YYYY HH:mm') : t.fromNow(),
        trackableId: location.trackableId,
      };
    });

  return (
    <MapWidget
      center={{ lng: -0.274, lat: 51.635 }}
      zoom={6}
      locations={mapLocations()}
      onMarkerClick={handleLocationSelection}
      className={classes.join(' ')}
    />
  );
};

MapWidgetContainer.displayName = 'MapWidgetContainer';

MapWidgetContainer.propTypes = {
  className: PropTypes.string,
};

MapWidgetContainer.defaultProps = {
  className: null,
};

export default MapWidgetContainer;
