/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
import React from 'react';
import PieChartWidget from 'components/wmc/PieChartWidget';
import { useHistory } from 'react-router-dom';
import { useEvents } from 'hooks';
import EventIcon from '@material-ui/icons/OfflineBoltOutlined';
import WidgetTitle from 'components/wmc/WidgetTitleAsLink';

const hoursTimeSpan = 24;
const timeSpanInMs = hoursTimeSpan * 60 * 60 * 1000;
const EventsWidgetContainer = () => {
  const [to] = React.useState(new Date());
  const [from] = React.useState(new Date(to.getTime() - timeSpanInMs));

  const events = useEvents({
    from,
    to,
  }).reduce((previous, current) => {
    if (current.workflowAppId) {
      const ev = previous[current.workflowAppId] || { value: 0 };
      ev.value += 1;
      ev.rest = current;
      previous[current.workflowAppId] = ev;
    }
    return previous;
  }, {});

  const chartData = Object.keys(events).map((k) => ({
    name: k,
    value: events[k].value,
    rest: events[k].rest,
  }));
  const history = useHistory();

  function onCellClick(data) {
    history.push(`/Events?appIds=${data.rest.workflowAppId}`);
  }

  const ChartTitle = (<WidgetTitle title={`Last ${hoursTimeSpan}h events`} to="/events" icon={<EventIcon />} />);

  return (
    <PieChartWidget
      data={chartData}
      title={ChartTitle}
      placeholder={`Sorry, no events in the last ${hoursTimeSpan}h`}
      onCellClick={onCellClick}
    />
  );
};

EventsWidgetContainer.displayName = 'EventsWidgetContainer';

export default EventsWidgetContainer;
